import React, { Fragment } from "react"
/* Import Global Font(s) */
import "~assets/fonts/standard-book/standard-book.css"

/* Import Global Normalize */
import "normalize.css"

/* Import Global Reset */
import "./reset.css"

/* Import Global CSS Variable(s) */
import "./variables.css"

/* Import Global Style(s) */
import "./global.css"

/* Import Global Component(s) */
import LoadingOverlay from "~components/loadingOverlay/loadingOverlay"
import TransitionRouter from "~components/transitionRouter/transitionRouter"

export default ({ location, children }) => {
  return (
    <Fragment>
      <LoadingOverlay />
      <TransitionRouter location={location}>{children}</TransitionRouter>
    </Fragment>
  )
}
