// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-temporary-dwellings-components-header-header-js": () => import("./../../../src/pages/temporary-dwellings/components/header/header.js" /* webpackChunkName: "component---src-pages-temporary-dwellings-components-header-header-js" */),
  "component---src-pages-temporary-dwellings-components-live-symposium-icon-live-symposium-icon-js": () => import("./../../../src/pages/temporary-dwellings/components/liveSymposiumIcon/liveSymposiumIcon.js" /* webpackChunkName: "component---src-pages-temporary-dwellings-components-live-symposium-icon-live-symposium-icon-js" */),
  "component---src-pages-temporary-dwellings-components-oral-history-radio-channel-icon-oral-history-radio-channel-icon-js": () => import("./../../../src/pages/temporary-dwellings/components/oralHistoryRadioChannelIcon/oralHistoryRadioChannelIcon.js" /* webpackChunkName: "component---src-pages-temporary-dwellings-components-oral-history-radio-channel-icon-oral-history-radio-channel-icon-js" */),
  "component---src-pages-temporary-dwellings-components-performance-library-icon-performance-library-icon-js": () => import("./../../../src/pages/temporary-dwellings/components/performanceLibraryIcon/performanceLibraryIcon.js" /* webpackChunkName: "component---src-pages-temporary-dwellings-components-performance-library-icon-performance-library-icon-js" */),
  "component---src-pages-temporary-dwellings-components-portal-press-icon-portal-press-icon-js": () => import("./../../../src/pages/temporary-dwellings/components/portalPressIcon/portalPressIcon.js" /* webpackChunkName: "component---src-pages-temporary-dwellings-components-portal-press-icon-portal-press-icon-js" */),
  "component---src-pages-temporary-dwellings-components-research-and-themes-icon-research-and-themes-icon-js": () => import("./../../../src/pages/temporary-dwellings/components/researchAndThemesIcon/researchAndThemesIcon.js" /* webpackChunkName: "component---src-pages-temporary-dwellings-components-research-and-themes-icon-research-and-themes-icon-js" */),
  "component---src-pages-temporary-dwellings-components-resources-icon-resources-icon-js": () => import("./../../../src/pages/temporary-dwellings/components/resourcesIcon/resourcesIcon.js" /* webpackChunkName: "component---src-pages-temporary-dwellings-components-resources-icon-resources-icon-js" */)
}

